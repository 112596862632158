import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import Cookies from "js-cookie"
import { rem } from "polished"
import { color } from "core/theme"
import { breakpoint } from "core/layout"

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 50;
`

const StyledCookiesConsent = styled(motion.div)`
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
  font-size: ${rem(14)};
  padding: ${rem(20)};
  transform: translateY(101%);

  > div {
    display: flex;
    flex-direction: column;
    max-width: ${rem(960)};
    margin: 0 auto;
  }

  p {
    margin: 0;
    margin-bottom: ${rem(20)};
    max-width: 60ch;
  }

  button {
    align-self: flex-end;
    appearance: none;
    background-color: transparent;
    border: none;
    color: ${color.brand.red};
    cursor: pointer;
    display: block;
    font-size: ${rem(16)};
    margin: -1em;
    margin-right: 0;
    padding: 1em;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${breakpoint.tablet`
    > div {
      flex-direction: row;
      justify-content: space-between;
    }

    p {
      margin-bottom: 0;
      margin-right: ${rem(40)};
    }

    button {
      align-self: center;
    }
  `};
`

const consentVariants = {
  hidden: {
    y: "101%",
  },
  visible: {
    y: "0%",
    transition: {
      delay: 2,
      type: "spring",
      damping: 20,
    },
  },
}

export default function CookiesConsent() {
  const [policy, setPolicy] = React.useState(
    () => typeof Cookies.get("consent-policy") !== "undefined"
  )
  return (
    !policy && (
      <Wrapper>
        <StyledCookiesConsent
          initial="hidden"
          animate="visible"
          variants={consentVariants}
        >
          <div>
            <p>
              We use cookies to enable the proper functioning and security of
              our website. By clicking Accept, you consent to the use of these
              cookies for analytics.
            </p>
            <button
              type="button"
              onClick={() => {
                Cookies.set("consent-policy", {
                  ess: 1,
                  func: 1,
                  anl: 1,
                  ts: Date.now(),
                })
                setPolicy(true)
              }}
            >
              Accept
            </button>
          </div>
        </StyledCookiesConsent>
      </Wrapper>
    )
  )
}
