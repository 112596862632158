import React from "react"
import { Link } from "gatsby"
import styled from "styled-components/macro"
import { rem } from "polished"
import { motion, useMotionValue, useSpring, transform } from "framer-motion"
import throttle from "lodash.throttle"
import { color } from "core/theme"
import { breakpoint, PageContainer } from "core/layout"
// import /* { ReactComponent as Logo } */ logo from "./assets/logo.svg"

const StyledHeader = styled(motion.header)`
  position: sticky;
  top: 0;
  z-index: 100;
`

const Surface = styled(motion.div)`
  background-color: ${color.surface.default};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -80px;
  left: 0;
  z-index: -1;
  width: 100%;
  height: calc(100% + 80px);
`

const oneThird = `${(1 / 3) * 100}%`

const FlexGroup = styled.div`
  display: flex;
  height: ${rem(80)};

  > a {
    flex: 1 1 ${oneThird};
    max-width: ${oneThird};

    color: ${color.brand.black};
    display: flex;
    font-size: ${rem(15)};
    height: 100%;
    text-decoration: none;

    > * {
      margin: auto;
    }

    > span {
      display: block;
    }

    > svg {
      height: ${rem(20)};
    }

    &.active {
      color: ${color.brand.red};
    }

    :hover {
      color: ${color.brand.red};
    }
  }

  ${breakpoint.laptop`
    height: ${rem(120)};

    > a {
      font-size: ${rem(18)};

      > svg {
        height: auto;
      }
    }
  `};
`

const spring = {
  damping: 50,
  stiffness: 200,
}

export default function Header({ isAlwaysElevated }) {
  const pageYOffset = useMotionValue(0)
  const scrollYDirection = useMotionValue(0)
  const z = useMotionValue(0)

  const opacity = useSpring(0, spring)
  const translateY = useSpring(0, spring)

  React.useEffect(() => {
    const elevatedThreshold = 80
    const hideThreshold = 240

    function onScroll() {
      const windowPageYOffset = window.pageYOffset
      const isElevated = windowPageYOffset > elevatedThreshold
      const shouldHide = windowPageYOffset > hideThreshold

      const newZ = isElevated ? 1 : 0
      z.set(newZ)

      const prevPageYOffset = pageYOffset.get()
      pageYOffset.set(windowPageYOffset)

      const newDirectionY = windowPageYOffset - prevPageYOffset
      const newScrollYDirection = newDirectionY > 0 ? 1 : -1
      scrollYDirection.set(shouldHide ? newScrollYDirection : -1)
    }

    const scrollYInput = [-1, 0, 1]
    const translateYOutput = [0, 0, -160]

    function updateTranslateY() {
      const latestScrollYDirection = scrollYDirection.get()
      const newTranslateY = transform(
        latestScrollYDirection,
        scrollYInput,
        translateYOutput
      )
      translateY.set(newTranslateY)
    }

    function updateOpacity() {
      const latestZ = z.get()
      opacity.set(latestZ)
    }

    const onScrollThrottled = throttle(onScroll, 250)
    window.addEventListener("scroll", onScrollThrottled, { passive: true })

    const unsubscribeScrollYDirection = scrollYDirection.onChange(
      updateTranslateY
    )

    let unsubscribeZ
    if (isAlwaysElevated) {
      opacity.set(1)
    } else {
      unsubscribeZ = z.onChange(updateOpacity)

      const windowPageYOffset = window.pageYOffset
      const shouldShow = windowPageYOffset > elevatedThreshold
      opacity.set(shouldShow ? 1 : 0)
    }

    return function cleanup() {
      window.removeEventListener("scroll", onScrollThrottled, { passive: true })
      unsubscribeScrollYDirection()

      if (unsubscribeZ) {
        unsubscribeZ()
      }
    }
  }, [isAlwaysElevated, pageYOffset, scrollYDirection, z, opacity, translateY])

  return (
    <StyledHeader style={{ translateY }}>
      <Surface style={{ opacity }} />
      <PageContainer>
        <FlexGroup>
          <Link to="/works">
            <span>Works</span>
          </Link>
          <Link to="/">
            <svg viewBox="0 0 99 35" fill="none" height="35" width="99">
              <title>We make stuff people like on internet - WRONG</title>
              <path d="M99 15.514H0v4.635h99v-4.636z" fill="#EE453B"></path>
              <path
                d="M12.946 34.527H8.948L4.378.284h4.665l2 17.784.095 1.135h.38l.095-1.135L13.803.284h3.617l2.19 17.784.095 1.135h.38l.096-1.135L22.18.284h4.664L22.18 34.432h-3.903l-2.38-16.175-.285-1.987h-.19l-.191 2.081-2.285 16.176zM38.457 34.527l-2.665-14.378h-1.333v14.378h-5.045V.284h5.045c5.997 0 8.568 3.405 8.568 9.838 0 3.405-.857 6.432-2.856 7.756l3.236 16.554c.096.095-4.95.095-4.95.095zM34.46 15.419c.667 0 1.618-.095 2.38-.757.762-.662 1.333-1.892 1.333-4.351 0-2.46-.571-3.69-1.333-4.352-.761-.662-1.618-.662-2.38-.662V15.42zM59.304 27.527c-.095 1.419-.285 2.649-.856 3.69-.381.945-1.047 1.797-2 2.553-.951.757-2.189 1.135-3.902 1.135-1.714 0-2.951-.378-3.903-1.135-.952-.662-1.523-1.608-2-2.554-.475-1.04-.76-2.365-.856-3.689V7.378c.095-1.419.38-2.648.857-3.689.38-.946 1.047-1.892 1.999-2.554C49.595.473 50.928 0 52.546 0c1.713 0 2.95.473 3.903 1.135.952.662 1.618 1.608 1.999 2.554.476 1.04.761 2.365.856 3.69v20.148zM54.26 7.378c0-.946-.19-1.608-.666-2.175-.19-.19-.571-.379-1.047-.379-.857 0-1.333.662-1.523 1.23-.096.378-.19.757-.19 1.23v20.148c0 .946.19 1.609.666 2.176.19.19.57.378.952.378.856 0 1.332-.567 1.523-1.23.095-.283.19-.756.19-1.229.095 0 .095-20.149.095-20.149zM68.157 16.649l-.286-1.23h-.38v19.013h-4.665V.284H67.3l4.95 17.784.381 1.608h.286V.284h4.664v34.148h-4.474l-4.95-17.783zM89.576 12.581V7.378c0-.946-.19-1.608-.667-2.175-.19-.19-.57-.379-1.047-.379-.857 0-1.332.662-1.523 1.23-.095.378-.19.757-.19 1.23V27.81c0 .946.19 1.608.666 2.175.19.19.571.379.952.379.857 0 1.333-.568 1.523-1.23.095-.284.19-.757.19-1.23v-6.148h-1.618V17.5h6.664v17.027h-2.951l-.572-1.608c-.951 1.135-2.284 1.986-3.902 1.986-1.619 0-2.76-.378-3.618-1.135-.856-.662-1.428-1.608-1.808-2.554-.381-1.04-.572-2.365-.572-3.689V7.378c.096-1.324.286-2.648.857-3.689.381-.946 1.047-1.892 2-2.554C84.91.473 86.243 0 87.861 0c1.714 0 2.951.473 3.903 1.135s1.618 1.608 2 2.554c.475 1.04.76 2.365.856 3.69v5.297h-5.045v-.095z"
                fill="#000"
              ></path>
            </svg>
          </Link>
          <Link to="/contacts">
            <span>Contacts</span>
          </Link>
        </FlexGroup>
      </PageContainer>
    </StyledHeader>
  )
}
