import { css } from "styled-components/macro"

// Material Design Breakpoints:
// https://material.io/design/layout/responsive-layout-grid.html#breakpoints

export const measurements = {
  siteWidth: 1440,
}

export const sizes = {
  site: measurements.siteWidth,
  laptopMedium: 1280,
  laptop: 1024,
  tablet: 768,
  mobile: 425,
}

const breakpoint = Object.keys(sizes).reduce((map, key) => {
  map[key] = (...args) => css`
    @media (min-width: ${sizes[key] / 16}em) {
      ${css(...args)}
    }
  `
  return map
}, {})

export const halfWidthImgSizes = `
  (min-width: ${sizes.site}px) ${sizes.site / 2}px,
  (min-width: ${sizes.tablet}px) 50vw,
  100vw
`

export const twoThirdsImgSizes = `
  (min-width: ${sizes.site}px) ${sizes.site * 0.75}px,
  (min-width: ${sizes.tablet}px) 75vw,
  100vw
`

export const fullWidthImgSizes = `
  (min-width: ${sizes.site}px) ${sizes.site}px,
  100vw
`

export default breakpoint
