import React from "react"
import { GlobalStyle } from "core/theme"
import Header from "../modules/Header/Header"
import CookiesConsent from "../modules/CookiesConsent"
import SEO from "../components/seo"

export default function PageLayout({ children }) {
  return (
    <>
      <SEO />
      <GlobalStyle />
      <Header isAlwaysElevated />
      {children}
      <CookiesConsent />
    </>
  )
}
