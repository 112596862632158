import React from "react"
import styled from "styled-components/macro"
import { abs } from "core/layout/utils"

export const Wrapper = styled.div``

export const RatioEnforcer = styled.div`
  position: relative;
  padding-top: ${({ ratio }) => ratio * 100}%;
`

export const ContentWrapper = styled.div`
  ${abs()};
`

export default function AspectRatio({ ratio = 3 / 4, children }) {
  return (
    <Wrapper>
      <RatioEnforcer ratio={ratio}>
        <ContentWrapper>{children}</ContentWrapper>
      </RatioEnforcer>
    </Wrapper>
  )
}
