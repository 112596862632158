import { css } from "styled-components/macro"
import { rem } from "polished"
import { color } from "core/theme"
import { breakpoint } from "core/layout"

const headingBase = css`
  color: ${color.brand.black};
  font-weight: 900;
  line-height: 1;
`

const primaryHeading = css`
  ${headingBase};
  font-size: ${rem(50)};

  ${breakpoint.laptop`
    font-size: ${rem(90)};
  `};
`

const secondaryHeading = css`
  ${headingBase};
  font-size: ${rem(34)};

  ${breakpoint.laptop`
    font-size: ${rem(60)};
  `};
`

const secondaryHeadingOnBlack = css`
  ${secondaryHeading};
  color: ${color.white};
`

const textBase = css`
  color: ${color.text.default};
`

const leadText = css`
  ${textBase};
  font-size: ${rem(22)};
  font-weight: 300;
  line-height: 1.5;

  ${breakpoint.laptop`
    font-size: ${rem(27)};
  `};
`

const bodyText = css`
  ${textBase};
  font-size: ${rem(15)};
  line-height: 1.6;

  ${breakpoint.laptop`
    font-size: ${rem(18)};
  `};
`

const caption = css`
  color: ${color.text.light};
  font-size: ${rem(12)};
  font-style: italic;
`

const scribble = css`
  color: ${color.brand.red};
  font-family: "Schoolbell", cursive;
  font-size: ${rem(27)};
`

const type = {
  primaryHeading,
  secondaryHeading,
  secondaryHeadingOnBlack,
  leadText,
  bodyText,
  caption,
  scribble,
}

export default type
