import styled from "styled-components/macro"
import { rem } from "polished"
import breakpoint, { measurements } from "../breakpoint"

const PageContainer = styled.div`
  margin: 0 auto;
  max-width: ${measurements.siteWidth}px;
  /* overflow: hidden; */
  padding: 0 20px;
  position: relative;

  /* FIXME: Quick-fix mobilā skata atkāpju neesamībai */
  > * + * {
    margin-top: ${rem(40)};
  }

  ${breakpoint.laptop`
    padding: 0 70px;
  `};

  ${breakpoint.site`
    padding: 0 148px;
  `};
`

export default PageContainer
