import { createGlobalStyle } from "styled-components/macro"
import { rem } from "polished"
import color from "./tokens/color"
// import arrowCursor from './assets/arrow.png'
// import pointerCursor from './assets/pointer.png'
import "@fontsource/poppins"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/700.css"
import "typeface-schoolbell"

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /*
    Optionally: Define a strong focus indicator for keyboard focus.
    If you choose to skip this step then the browser's default focus
    indicator will be displayed instead.
  */
  .js-focus-visible .focus-visible {}

  /*
    Optionally: Define text selection color and background-color
  */
 ::selection {
    color: ${color.white};
    background-color: ${color.brand.red};
  }

  /*
    https://css-tricks.com/almanac/properties/s/scrollbar/
  */
  ::-webkit-scrollbar {
    width: ${rem(6)};
    height: ${rem(6)};
  }
  ::-webkit-scrollbar-track {
    background-color: ${color.brand.black};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${color.brand.red};
    &:focus {
      outline: none;
    }
  }

  /*
    Disable Firefox dotted focus outline
  */
  :-moz-focusring {
    outline: none;
  }

  html {
    background-color: ${color.background.default};
    color: ${color.text.default};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-tap-highlight-color: transparent;
  }

  body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    overflow-x: hidden;
  }

  a {
    color: inherit;

    /* &:focus {
      outline: 2px solid ${color.brand.red};
    } */
  }

  svg {
    display: block;
  }
`

export default GlobalStyle
