const MATERIAL_STANDARD = "cubic-bezier(0.4, 0.0, 0.2, 1)"
const MATERIAL_ACCELERATE = "cubic-bezier(0.4, 0.0, 1, 1)"
const MATERIAL_DECELERATE = "cubic-bezier(0.0, 0.0, 0.2, 1)"

export const ease = {
  inOut: MATERIAL_STANDARD,
  out: MATERIAL_ACCELERATE,
  in: MATERIAL_DECELERATE,
}

export const duration = {
  fast: {
    in: "150ms",
    out: "75ms",
  },
  normal: {
    in: "250ms",
    out: "200ms",
  },
  slow: {
    in: "350ms",
    out: "300ms",
  },
}
