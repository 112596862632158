import { transparentize } from "polished"

const WHITE = "#FFFFFF"
const BLACK = "#000000"

const BRAND_RED = "#EE453B"

const BACKGROUND = "#F7F8FB"
const SURFACE = "#FFFFFF"

const DARK_GREY = "#333333"
const LIGHT_GREY = "#888888"

const color = {
  white: WHITE,
  black: BLACK,

  accent: {
    default: BRAND_RED,
  },

  brand: {
    red: BRAND_RED,
    black: BLACK,
  },

  text: {
    default: DARK_GREY,
    light: LIGHT_GREY,
    dark: BLACK,
  },

  background: {
    default: BACKGROUND,
    ultralight: transparentize(0.95, LIGHT_GREY),
    light: transparentize(0.5, LIGHT_GREY),
  },

  surface: {
    default: SURFACE,
  },
}

export default color
