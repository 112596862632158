import React from "react"
import styled, { css } from "styled-components/macro"
import { rem } from "polished"
import breakpoint from "../breakpoint"

const removeChildMarginsStyle = css`
  > * {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.tablet`
    flex-direction: row;
  `};

  ${({ removeChildMargins }) =>
    removeChildMargins &&
    css`
      > div {
        ${removeChildMarginsStyle};
      }
    `};
`

const Left = styled.div`
  flex: ${({ flex }) => flex};
  height: 0%;

  ${breakpoint.tablet`
    margin-right: ${rem(18)};
  `};

  ${breakpoint.laptop`
    margin-right: ${rem(36)};
  `};
`

const Right = styled.div`
  flex: ${({ flex }) => flex};

  ${breakpoint.tablet`
    margin-left: ${rem(18)};
  `};

  ${breakpoint.laptop`
    margin-left: ${rem(36)};
  `};
`

export default function IntroBlock({
  flex,
  left,
  right,
  removeChildMargins = false,
}) {
  const [l, r] = flex ? flex.split("/") : ["1 1 50%", "1 1 50%"]
  return (
    <Layout removeChildMargins={removeChildMargins}>
      {left && <Left flex={l}>{left()}</Left>}
      {right && <Right flex={r}>{right()}</Right>}
    </Layout>
  )
}
